<template>
  <div v-if="knife" id="modal" class="modal" @click="closeModal">
    <div class="knives__modal">
      <div class="knives__modal-inner" @click.stop>
        <div class="knives__modal-arrows">
          <button
            :disabled="knifeId === 1"
            class="knives__modal-btn knives__modal-prev"
            @click="prevKnife()"
          >
            <img src="@/assets/img/knives/chevron-prev.svg" alt="" />
          </button>
          <button
            :disabled="knifeId === knifes.length"
            class="knives__modal-btn knives__modal-next"
            @click="nextKnife()"
          >
            <img src="@/assets/img/knives/chevron-next.svg" alt="" />
          </button>
        </div>
        <button class="knives__modal-close" @click="closeModal">
          <img src="@/assets/img/icons/x.svg" alt="" />
        </button>
        <div class="knives__modal-line"></div>
        <div
          v-if="knife.youtube"
          class="knives__modal-youtube"
          v-html="knife.youtube"
        ></div>

        <div v-else class="knives__modal-img">
          <div class="knives__modal-img--left">
            <div class="knives__modal-img--title">{{ $t(knife.title) }}</div>
            <div class="knives__product-discount">
              <span class="knives__product-discount--number">{{
                knife.discount
              }}</span>
            </div>
          </div>

          <img :src="knife.image" alt="" />
        </div>
        <div class="knives__modal-content">
          <div class="knives__modal-title">{{ $t(knife.title) }}</div>
          <div class="knives__modal-subtitle">
            {{ $t(knife.compound) }}
          </div>
          <p class="knives__modal-text">
            {{ $t(knife.text) }}
          </p>
          <div class="knives__product-price">
            <p class="knives__product-oldprice">
              {{ knife.oldPrice }}
              <img src="@/assets/img/knives/tenge.svg" alt="" />
            </p>
            <p class="knives__product-newprice">
              {{ knife.newPrice
              }}<img src="@/assets/img/knives/tenge.svg" alt="" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppKnivesModal",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      knifeId: null,
    };
  },
  computed: {
    ...mapState("knivesModule", ["knifes"]),
    knife() {
      return this.knifes.find((e) => e.id === this.knifeId);
    },
  },

  mounted() {
    this.knifeId = this.id;
    if (this.$isMobile()) {
      setTimeout(() => {
        document.querySelector("#modal").scrollTop = 400;
      }, "100");
      setTimeout(() => {
        this.addClassScrollBtn();
      }, "300");
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    nextKnife() {
      this.knifeId++;
    },
    prevKnife() {
      this.knifeId--;
    },
    addClassScrollBtn() {
      const modal = document.querySelector("#modal");
      const onscroll = (e) => {
        e.stopPropagation();
        let scroll = modal.pageYOffset || modal.scrollTop;
        if (scroll === 0) {
          setTimeout(() => {
            this.$emit("close");
          }, "100");
        } else {
          return;
        }
      };
      modal.addEventListener("scroll", onscroll);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  scroll-behavior: smooth;
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar {
    height: 0;
    opacity: 0;
  }

  &::-webkit-scrollbar-button {
    height: 0;
    opacity: 0;
  }
}
</style>
